<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="">
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
          >
            <b-alert
                variant="primary"
                show

            >
              <div class="alert-body">
                <span>我是新增</span>
              </div>
            </b-alert>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <b-alert
                variant="danger"
                show

            >
              <div class="alert-body">
                <span>我是删除</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>



      </div>
      <ValidationObserver ref="observerItemList">
        <b-table
            sticky-header
            noCollapse
            :tbody-tr-class="rowClass"
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
            v-model="dataList"

        >

          <!-- Columns -->
          <template #cell(name)="data">
            [#{{ data.item.itemId }}]{{ data.item.name }}
            <feather-icon
                icon="TwitchIcon"
                size="21"
                variant="success"
                :id="`item-row-${data.item.id}`"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '69码：'+data.item.product.code+
              '<br>'+'箱规：'+data.item.product.box_quantity+
              '</div>'"
            />

          </template>

          <template #cell()="data">
            <change-label :change-data="data.value" :convert-type="data.value.convertType"></change-label>
          </template>

        </b-table>
      </ValidationObserver>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import purchaseorderitemUseList from './purchaseorderitemViewUseList'
import purchaseorderitemStore from './purchaseorderitemStore'
import ChangeLabel from '@/views/components/xy/change-label'
export default {
  components: {
    BModal,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ChangeLabel
  },
  data() {
    return {
      selectedItem: {},
      dataList: [],

    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') { return }

      if (item.flag === 0) {
        return 'table-danger'
      }
      if (item.flag === 1) {
        return 'table-primary'
      }
    },
  },
  props: {
    order_id: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('purchaseorderitem')) store.registerModule('purchaseorderitem', purchaseorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorderitem')) store.unregisterModule('purchaseorderitem')
    })


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // UI
    } = purchaseorderitemUseList({
      order_id: props.order_id
    })


    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.delLine{
  z-index: 999;
  position: absolute;

  height: 1px;
  line-height: 1px;
  border-top: 1px solid orange !important;
  transform: rotate(0.0deg); /*倾斜度*/
  display: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
