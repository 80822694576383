<template>
  <b-card
      class="contract-edit-wrapper"
  >
    <!-- form -->
    <b-form id="contractForm" class="edit-form mt-2" v-if="purchaseOrder">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>

        <b-col md="4" v-for="data in basicInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType">
          </change-label>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">订单信息</div>
        </b-col>

        <b-col md="4" v-for="data in orderInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType">
          </change-label>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">合同信息</div>
        </b-col>

        <b-col md="4" v-for="data in agreementInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType">
          </change-label>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">财务信息</div>
        </b-col>
        <b-col :md="data.col || 4" v-for="data in financeInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType"
                        :label-col="data.labelCol"
          >
          </change-label>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">其他信息</div>
        </b-col>
        <b-col md="12" v-for="data in otherInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType"
                        :label-col="data.labelCol"
          >
          </change-label>
        </b-col>
        <b-col md="12">
        <div class="inner-card-title">明细列表</div>
        </b-col>
        <b-col>
          <purchase-order-item-list-view ref="itemList" :order_id="purchaseOrder.orderId" >

          </purchase-order-item-list-view>
        </b-col>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import { onUnmounted, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import purchaseorderStore from './purchaseorderStore'
import { useToast } from 'vue-toastification/composition'
import ChangeLabel from '@/views/components/xy/change-label'
import purchaseorderitemStore from '@/views/apps/purchaseorderitem/purchaseorderitemStore'
import PurchaseOrderItemListView from '@/views/apps/purchaseorderitem/PurchaseOrderItemListView'

export default {
  components: {
    PurchaseOrderItemListView,
    ChangeLabel,
    useToast,
  },
  data() {
    return {}
  },
  methods: {},
  setup() {
    const {
      route,
    } = useRouter()

    const state = reactive({
      type: 1,
      purchaseOrder: undefined,
      basicInfo: [],
      orderInfo: [],
      agreementInfo: [],
      financeInfo: [],
      logisticsInfo: [],
      otherInfo: [],
    })

    // Register module
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)
    if (!store.hasModule('purchaseorderitem')) store.registerModule('purchaseorderitem', purchaseorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorder')) store.unregisterModule('purchaseorder')
      if (store.hasModule('purchaseorderitem')) store.unregisterModule('purchaseorderitem')
    })
    const view = function () {
      let id = route.value.query.id || 0
      state.type = route.value.query.type || 1
      store.dispatch('purchaseorder/changeView', { id: id })
          .then(res => {
            state.purchaseOrder = res.data.data
            toPage(res.data.data)
          })
    }

    const toPage = function (data) {
      state.basicInfo = [
        {
          label: '申请人',
          changeData: data['applierName']
        },
        {
          label: '申请部门',
          changeData: data['applierDepartmentName'],
        },
        {
          label: '所属组织',
          changeData: data['applierCompanyName']
        },
      ]
      state.orderInfo = [
        {
          label: '业务类型',
          changeData: data['typeId'],
          convertType: 'business_type'
        },
        {
          label: '是否内部交易',
          changeData: data['isInsidetrade'],
          convertType: 'yesno'
        },
        {
          label: '采购订单编号',
          changeData: data['orderNo']
        },

        {
          label: 'ERP采购订单号',
          changeData: data['orderNoErp'],
        },
        {
          label: '原产地',
          changeData: data['sourcePlace'],
        },
        {
          label: '签约主体名称',
          changeData: data['companyName']
        },
        {
          label: '采购员名称',
          changeData: data['purchaserName'],
        },
        {
          label: '联系电话',
          changeData: data['purchaserMobile'],
        },
        {
          label: '供应商',
          changeData: data['supplierName']
        },
        {
          label: '联系人',
          changeData: data['supplierContactName'],
        },
        {
          label: '供应商联系电话',
          changeData: data['supplierContactMobile'],
        },
        {
          label: '供应商订单号',
          changeData: data['supplierOrderNo'],
        },
      ]
      state.agreementInfo = [
        {
          label: '合同编号',
          changeData: data['contractNo'],
        },
        {
          label: '合同类型',
          changeData: data['contractType'],
          convertType: 'agreement_specific_type_CG'
        },
        {
          label: '发票类型',
          changeData: data['invoiceType'],
          convertType: 'invoice_type'
        },
        {
          label: '付款方式',
          changeData: data['paymentMethod'],
          convertType: 'agreement_pay_method'
        },
        {
          label: '结算方式',
          changeData: data['settlementMethod'],
        }
      ]
      state.financeInfo = [
        {
          label: '是否预付款',
          changeData: data['needAdvance'],
          convertType: 'yesno'
        },
        {
          label: '结算次数',
          changeData: data['settlementTimes'],
        },
        {
          label: '预计采购费用',
          changeData: data['purchaseTotal'],
        },

        {
          col: 2,
          labelCol: 5,
          label: '首款比例/%',
          changeData: data['firstPayPercent'],
        },
        {
          col: 2,
          labelCol: 5,
          label: '首款金额',
          changeData: data['firstPayAmount'],
        },
        {
          col: 4,
          label: '首款付款条件',
          changeData: data['firstPayCondition'],
        },
        {
          col: 4,
          label: '首款预计付款时间',
          changeData: data['firstPayPaydate'],
          convertType: 'date'
        },
        {
          col: 2,
          labelCol: 5,
          label: '中期款比例/%',
          changeData: data['middlePayPercent'],
        },
        {
          col: 2,
          labelCol: 5,
          label: '中期款金额',
          changeData: data['middlePayAmount'],
        },
        {
          col: 4,
          label: '中期款付款条件',
          changeData: data['middlePayCondition'],
        },
        {
          col: 4,
          label: '中期款预计付款时间',
          changeData: data['middlePayPaydate'],
          convertType: 'date'
        },
        {
          col: 2,
          labelCol: 5,
          label: '尾款比例/%',
          changeData: data['lastPayPercent'],
        },
        {
          col: 2,
          labelCol: 5,
          label: '尾款金额',
          changeData: data['lastPayAmount'],
        },
        {
          col: 4,
          label: '尾款付款条件',
          changeData: data['lastPayCondition'],
        },
      ]
      state.logisticsInfo = [
        {
          label: '运输方式',
          changeData: data['deliveryType'],
          convertType: 'delivery_method_purchase'
        },
        {
          label: '货物起运地',
          changeData: data['startPlace'],
        },
        {
          label: '交货目的地',
          changeData: data['endPlace'],
        },
        {
          label: '预计发货时间',
          changeData: data['deliveryTime'],
          convertType: 'date'
        },
        {
          label: '要求到货日期',
          changeData: data['arrivalTime'],
          convertType: 'date'
        }
      ]
      state.otherInfo = [
        {
          labelCol: 1,
          label: '变更理由及影响',
          changeData: data['oaReason'],
        },
        {
          labelCol: 1,
          label: '备注',
          changeData: data['remark'],
        },
        {
          labelCol: 1,
          label: '订单(双章)扫描件上传',
          changeData: data['attachmentsOrder'],
          convertType: 'file'
        },
        {
          labelCol: 1,
          label: '相关附件',
          changeData: data['attachmentsIds'],
          convertType: 'file'
        },
        {
          labelCol: 1,
          label: '毛利测算表附件',
          changeData: data['attachmentsGrossprofit'],
          convertType: 'file'
        },
      ]
    }
    const cancel = function () {
      this.$router.go(-1)
    }

    view()
    return {
      view,
      cancel,
      ...toRefs(state)
    }

  },
  watch: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
