import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchaseorderitemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  const formatter = function (value,key,item,type){
    // value['before']
    return type

  }

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: '名称'},
    { key: 'qty', label: '数量', tdClass:'delLine'},
    { key: 'productCostTax', label: '含税成本',},
    // { key: 'productCostSubtotal', label: '小计'},
    // { key: 'productCost', label: '成本'},
    { key: 'productCostTaxSubtotal', label: '税后小计'},
    { key: 'taxRate', label: '税率',formatter:value => Object.assign(value,{convertType:'tax_rate'})},
    { key: 'expectedGrossProfitRate', label: '预计毛利率/%'},
    { key: 'taxSubtotal', label: '税额'},
    { key: 'extraCost', label: '附加费'},
    { key: 'extraCostSubtotal', label: '附加费小计'},
    { key: 'cost', label: '金额'},
    { key: 'subtotal', label: '价税合计'},
    { key: 'isGift', label: '是否赠品',formatter:value => Object.assign(value,{convertType:'yesno'})},
  ]

  const start = ref(1)
  const limit = ref(1000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('item_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('purchaseorderitem/changeView', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        order_id : config.order_id,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '采购单明细列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
  }
}
